<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione uma Disciplina423 </v-card-title>

        <v-select
          :items="disciplinas"
          item-text="descricao"
          item-value="id"
          v-model="disciplina"
          return-object
          label="Selecione uma disciplina"
          solo
          @change="selecionarDisciplina"
        ></v-select>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    gestaoDeAulasId: {
      type: Number,
    },
    disciplinas: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      disciplina: null,
    };
  },
  methods: {
    selecionarDisciplina() {
      this.$emit("status", false); // Fechar o modal
      this.$emit("disciplinaSelecionada", this.disciplina); // Emitir a disciplina selecionada
    },
  },
};
</script>
