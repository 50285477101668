<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'disciplinas' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Disciplina
        </h1>
      </v-col>
    </v-row>
    <!-- <ValidationObserver v-slot="{ handleSubmit }"> -->
    <form @submit.prevent="submitForm" :disabled="submittingForm">
      <v-row>
        <v-col class="pt-0 pb-0" cols="1">
          <!-- <ValidationProvider name="Código" rules="required" v-slot="{ errors }"> -->
          <e-label>Código</e-label>
          <v-text-field v-model="form.codigo" dense solo />
          <!-- </ValidationProvider> -->
        </v-col>
        <v-col class="pt-0 pb-0" cols="4">
          <!-- <ValidationProvider name="Descrição" rules="required" v-slot="{ errors }"> -->
          <e-label>Descrição</e-label>
          <v-text-field v-model="form.descricao" dense solo />
          <!-- </ValidationProvider> -->
        </v-col>
        <v-col class="pt-0 pb-0" cols="7">
          <e-label>Área de conhecimento</e-label>
          <e-autocomplete
            :items="area_conhecimento"
            :return-object="false"
            :item-value="(area) => area.id"
            :item-text="(area) => area.descricao"
            :loading="carregandoAreas"
            v-model="form.area_conhecimento_id"
            label="Selecione uma opção"
            dense
            solo
          />
        </v-col>
        <v-col cols="12">
          <v-btn
            color="primary"
            :disabled="submittingForm"
            :loading="submittingForm"
            type="submit"
            block
            >Enviar Dados</v-btn
          >
        </v-col>
      </v-row>
    </form>
    <!-- </ValidationObserver> -->
  </main-template>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Disciplinas", ["disciplinasList", "carregandoDisciplinas"]),
    ...mapGetters("Disciplinas", ["disciplinas"]),
    form() {
      if (!this.disciplinas || !this.editing) return {};
      const { disciplina_id } = this.$route.params;
      const disciplina = this.disciplinas.get(parseInt(disciplina_id, 10));
      return {
        ...disciplina,
      };
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      submittingForm: false,
      area_conhecimento: [],
      carregandoAreas: false,
    };
  },
  methods: {
    ...mapActions("Disciplinas", ["loadDisciplinas"]),
    async submitForm() {
      this.submittingForm = true;
      try {
        if (this.editing) {
          await this.$services.disciplinasService.atualizarDisciplina(this.form);
        } else {
          await this.$services.disciplinasService.criarDisciplina(this.form);
        }
        this.$toast.success("Disciplina Atualizada com sucesso");
        this.$router.push({ name: "disciplinas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    async loadData() {
      try {
        if (this.disciplinas.length === 0) {
          this.$loaderService.open("Carregando dados");
        }
        await Promise.all([this.loadDisciplinas()]);
        this.area_conhecimento = await this.$services.disciplinasService.areaConhecimento();

        this.$loaderService.close();
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
