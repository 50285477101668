<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1200px">
      <v-card>
        <v-card-title>
          <span class="text-h5">TERMOS DE ACEITE DE CONTRATO</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-card>
              <v-tabs v-model="tabIndex" vertical>
                <v-tab :disabled="tabDisabled[0]">
                  <v-icon left> mdi-account </v-icon>
                  Dados Matrícula
                </v-tab>
                <v-tab :disabled="tabDisabled[1]">
                  <v-icon left> mdi-account </v-icon>
                  Dados Pessoais&nbsp;&nbsp;&nbsp;
                </v-tab>
                <v-tab :disabled="tabDisabled[3]">
                  <v-icon left> mdi-lock </v-icon>
                  Aceitar Termos&nbsp;&nbsp;&nbsp;
                </v-tab>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <div class="text-center">
                        <v-simple-table height="120px">
                          <thead>
                            <tr>
                              <th class="text-left">Nome</th>
                              <th class="text-left">Matrícula</th>
                              <th class="text-left">Turma</th>
                              <th class="text-left">Ano</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr style="cursor: pointer">
                              <td class="text-left" @click="nextTab">
                                {{ dadosMatricula.aluno?.nomecompleto ?? "" }}
                              </td>
                              <td class="text-left" @click="nextTab">
                                {{
                                  dadosMatricula.matricula?.codigo
                                    ? dadosMatricula.matricula.codigo
                                    : "-"
                                }}
                              </td>
                              <td class="text-left" @click="nextTab">
                                {{ dadosMatricula.turma?.descricao ?? "" }}
                              </td>
                              <td class="text-left" @click="nextTab">
                                {{
                                  dadosMatricula.turma
                                    ? getAnoCorrespondente(dadosMatricula.turma.ano_id)
                                    : ""
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <ValidationObserver v-slot="{ handleSubmit }">
                        <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
                          <ValidationProvider
                            name="Nome"
                            rules="required|min:4"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="name"
                              :error-messages="errors"
                              :counter="10"
                              label="Nome Completo"
                              required
                            ></v-text-field>
                          </ValidationProvider>
                          <ValidationProvider
                            name="CPF"
                            rules="required|min:11"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="cpf"
                              :error-messages="errors"
                              label="CPF"
                              v-mask="'###.###.###-##'"
                              required
                            ></v-text-field>
                          </ValidationProvider>
                          <div>
                            <v-menu
                              ref="menu1"
                              v-model="menu1"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="dateFormatted"
                                  label="Date"
                                  hint="MM/DD/YYYY"
                                  persistent-hint
                                  prepend-icon="mdi-calendar"
                                  v-bind="attrs"
                                  @blur="date = parseDate(dateFormatted)"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="date"
                                no-title
                                @input="menu1 = false"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                          <v-btn @click="nextTab" type="submit" class="mt-2">Avançar</v-btn>
                        </form>
                      </ValidationObserver>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <div>
                        <!-- iframe -->
                        <embed width="900" height="780" :src="pdf" />
                      </div>
                      <v-checkbox
                        v-model="checkbox"
                        :label="`declaro que li e concordo com o contrato.`"
                      >
                      </v-checkbox>
                      <div class="text-center mt-4">
                        <v-btn @click="aceitarTermos" :disabled="!checkbox">Concluir</v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    name: "",
    cpf: "",
    select: null,
    dadosMatricula: [],
    checkbox: false,
    dialog: true,
    activePicker: null,
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
    menu: false,
    dadosContrato: [],
    tabIndex: 0,
    tabDisabled: [false, false, false],
    submittingForm: false,
    pdf: "",
    termosAceitos: false,
    dateFormatted: null,
    menu1: false,
  }),
  async created() {
    await this.contratoPrestacoes();
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },

  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`; // Formato brasileiro
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    insertDadosContrato() {
      this.dadosContrato.push({
        idMatricula: this.dadosMatricula.matricula.id,
        nome: this.name,
        cpf: this.cpf,
        dataNasc: this.date,
        aceiteContrato: this.termosAceitos,
      });

      this.postDataContract(this.dadosContrato[1], this.dadosMatricula.matricula.id);
    },
    submitForm() {
      this.submittingForm = true;
      try {
        this.insertDadosContrato();
        this.getContrato(this.dadosMatricula.matricula.id);
        this.clearForm();
      } catch (error) {
        this.$handleError(error);
      }
      this.submittingForm = false;
    },
    aceitarTermos() {
      this.termosAceitos = true;
      this.name = this.dadosMatricula.aluno.nomecompleto;
      this.cpf = this.dadosMatricula.aluno.cpf;
      this.date = this.dadosMatricula.aluno.data_nascimento;
      this.insertDadosContrato();
      this.dialog = false;
      this.$toast.success("Termo de contrato confirmado com sucesso!");
    },
    clearForm() {
      this.date = null;
      this.name = "";
      this.cpf = "";
      this.select = null;
      this.checkbox = false;
    },
    async contratoPrestacoes() {
      this.$loaderService.open("Baixando contrato de prestações");
      try {
        const matriculaId = await this.getMatriculaContrato();
        this.dadosMatricula = matriculaId;
        if (this.dadosMatricula === null) {
          this.$toast.error("Matricula não encontrada");
          this.dialog = false;
          return;
        }

        this.name = this.dadosMatricula.aluno.nomecompleto;
        this.cpf = this.dadosMatricula.aluno.cpf;
        this.date = this.dadosMatricula.aluno.data_nascimento;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async getContrato(matricula_id) {
      try {
        const response = await this.$services.relatoriosService.exibirContrato(matricula_id);
        this.pdf = response;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async getMatriculaContrato() {
      this.$loaderService.open("Capturando Matrícula");
      try {
        const response = await this.$services.relatoriosService.getMatriculaContrato();
        return response.data;
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    nextTab() {
      if (this.tabIndex < 2) {
        if (this.tabIndex === 1 && !this.isFormValid()) {
          console.log("Por favor, preencha todos os campos obrigatórios antes de avançar.");
          return;
        }
        this.tabIndex++;
      }
      for (let i = 0; i < this.tabIndex; i++) {
        this.$set(this.tabDisabled, i, true);
      }
    },
    getAnoCorrespondente(anoId) {
      const anoCorrespondente = this.$constants.selectAno.find(
        (ano) => Object.keys(ano)[0] === anoId.toString()
      );
      return anoCorrespondente ? Object.values(anoCorrespondente)[0] : "Ano não encontrado";
    },
    isFormValid() {
      return this.name && this.cpf && this.date;
    },
    async postDataContract(form, matricula_id) {
      try {
        const response = await this.$services.matriculasService.contratoAluno(form, matricula_id);
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>
