import Axios from "@/plugins/Axios";
import store from "@/store";
import Disciplina from "@/Models/Disciplina";

export class DisciplinasService {
  async syncAll() {
    const response = await Axios().get("disciplinas");
    const disciplinas = response.data.map((disciplina) => new Disciplina(disciplina));
    store.commit("Disciplinas/setDisciplinas", disciplinas);
    return disciplinas;
  }

  async criarDisciplina(disciplina) {
    const response = await Axios().post("disciplinas", disciplina);
    const novaDisciplina = response.data;
    store.commit("Disciplinas/adicionarDisciplina", novaDisciplina);
    return novaDisciplina;
  }

  async atualizarDisciplina(disciplina) {
    const response = await Axios().put(`disciplinas/${disciplina.id}`, disciplina);
    const disciplinaAtualizada = response.data;
    store.commit("Disciplinas/atualizarDisciplina", disciplinaAtualizada);
    return disciplinaAtualizada;
  }

  async deletarDisciplina(disciplina) {
    const response = await Axios().delete(`disciplinas/${disciplina.id}`);
    const disciplinaDeletada = response.data;
    store.commit("Disciplinas/deletarDisciplina", disciplina.id);
    return disciplinaDeletada;
  }

  async recuperarDisciplina(disciplina) {
    return this.atualizarDisciplina({ ...disciplina, deleted_at: null });
  }

  async areaConhecimento() {
    const response = await Axios().get("disciplinas/area-conhecimento");
    const areaConhecimento = response.data;
    return areaConhecimento;
  }
  async visualizarMaterialDeAula(matricula, turma, disciplina) {
    const response = await Axios().get(`disciplinas/${matricula}/visualizar-material-de-aula/${turma}/${disciplina}`);
    return response.data;
  }
  async getDisciplinasDoCurso(cursoId){
    const response = await Axios().get(`disciplinas/por-curso/${cursoId}`);
    return response.data;
  }
  async downloadMaterialDisciplina(material, turma, disciplina) {
    try {
      const response = await Axios().get(`/disciplinas/${material}/download-material-disciplina/${turma}/${disciplina}`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `material_disciplina_${material}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Erro ao baixar o material da disciplina:', error);
    }
  }

}
export default new DisciplinasService();
