<template>
  <div class="template-wrapper">
    <div>
      <e-left-menu />
    </div>
    <div class="main-content">
      <v-app-bar elevate-on-scroll width="100%" color="primary" style="overflow: hidden">
        <v-btn icon @click="toggleMenu" color="white">
          <v-icon> mdi mdi-menu </v-icon>
        </v-btn>
        <!-- <span class="text-subtitle-1 d-none d-sm-block" style="color: white" v-if="!hideFranquia">
          {{ user ? user.franquia.descricao : "..." }}
        </span> -->
        <span class="text-subtitle-1 d-none d-sm-block" style="color: white">
          {{ user ? user.franquia?.descricao : "..." }}
        </span>
        <v-spacer />
        <v-list color="secondary" max-width="260px" class="pa-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-avatar>
                  <e-user-avatar current />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="white--text">
                    {{ (user ? user.name : "Carregando...") | truncate(15) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <e-user-role link />
                  </v-list-item-subtitle> </v-list-item-content
                ><v-list-item-action>
                  <v-icon>mdi-menu-down</v-icon>
                </v-list-item-action>
              </v-list-item>
            </template>
            <v-list dense>
              <v-list-item dense link @click="openSelecionarFranquiaModal = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-cogs</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Alterar unidade escolar</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="openAtualizarSenhaModal = true">
                <v-list-item-avatar>
                  <v-icon small>fa fa-key</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Alterar senha</v-list-item-title>
              </v-list-item>
              <v-list-item dense link :to="{ name: 'logout' }">
                <v-list-item-avatar>
                  <v-icon small>fa fa-sign-out-alt</v-icon>
                </v-list-item-avatar>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </v-app-bar>

      <!-- <v-dialog v-model="modalOcioso" persistent max-width="500">
        <v-card>
          <v-card-text class="text-center text-h5 pt-4 font-weight-bold">
            Sua sessão está proxima de expirar!
          </v-card-text>
          <v-card-text class="text-center"
            >Esta faltando
            <v-chip class="ma-2" color="orange" text-color="white">
              {{ this.tempoFormatado }}
            </v-chip>
            segundos.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="
                limparIntervalo(idInterval);
                dataInicial();
                temporizador();
                modalOcioso = false;
              "
            >
              Permanecer logado
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> -->

      <v-system-bar color="yellow lighten-5">
        <p class="lineUp caption text--black mt-4">v1.0.3.1954</p>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <!-- <span class="caption text--black mr-2">Tempo de sessão</span>
        <v-icon>mdi-timer-outline</v-icon>
        <span class="caption text--black mr-2">{{ tempoFormatado }}</span> -->
      </v-system-bar>

      <v-container>
        <slot />
        <v-row style="min-height: 90px">
          <v-col>
            <span></span>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <e-change-password-modal v-model="openAtualizarSenhaModal" />
    <e-selecionar-franquia-modal v-model="openSelecionarFranquiaModal" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EChangePasswordModal from "../../../components/EChangePasswordModal.vue";

const exibirFinanceiro = process.env.VUE_APP_ATIVAR_FINANCEIRO === "true";
export default {
  name: "MainTemplate",
  components: {
    EChangePasswordModal,
  },
  props: {
    hideFranquia: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    ...mapGetters("Franquias", ["franquias"]),
    ...mapGetters("MainTemplate", ["menuOpen"]),
  },
  mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$showWidget());
    this.$store.commit("MainTemplate/setMenuOpen", !this.menuOpen);
    setTimeout(() => {
      this.$store.commit("MainTemplate/setMenuOpen", true);
    }, 2000);

    this.dataInicial();
    // this.temporizador();
  },
  data() {
    return {
      idInterval: null,
      modalOcioso: false,
      active: false,
      tempo: null,
      tempoFormatado: null,
      durabilidadeDaVidaDoUsuario: 0,
      selectedItem: 0,
      openAtualizarSenhaModal: false,
      openSelecionarFranquiaModal: false,
      exibirFinanceiro,
      menu: {
        items: [
          {
            icon: "fa fa-home",
            title: "Início",
            exibir: true,
            children: [{ title: "Ir para Início", to: { name: "home" }, icon: "" }],
          },
          {
            icon: "fa fa-user-graduate",
            title: "Matrículas",
            exibir: true,
            children: [
              { title: "Ver Matrículas", to: { name: "matriculas" }, icon: "" },
              // { title: "Adicionar matricula", to: "#", icon: "" },
              { title: "Ver Alunos", to: { name: "alunos" }, icon: "" },
              {
                title: "Pesquisa avançada",
                to: { name: "alunos-pesquisa-avancada" },
                icon: "",
              },
            ],
          },
          {
            icon: "fa fa-graduation-cap",
            title: "Turmas",
            exibir: true,
            children: [
              { title: "Ver turmas", to: { name: "turmas" }, icon: "" },
              // { title: "Adicionar turmas", to: "#", icon: "" },
              // { type: "spacer" },
              {
                title: "Gestão de séries",
                to: { name: "series" },
                icon: "fa fa-bookmark",
                permissions: ["series.*", "series.criar"],
              },
              {
                title: "Gestão de disciplinas",
                to: { name: "disciplinas" },
                icon: "fa fa-bookmark",
                permissions: ["disciplinas.*", "disciplinas.criarDisciplina"],
              },
              // { title: "Gestão de disciplinas", to: "#", icon: "" },
              // { title: "Gestão de cursos", to: "#", icon: "" },
            ],
          },
          {
            icon: "fa fa-chalkboard-teacher",
            title: "Diário online",
            exibir: true,
            children: [
              {
                title: "Listar todos",
                to: { name: "gestoesDeAulas" },
                icon: "",
                permissions: ["gestoesDeAulas.*", "gestoesDeAulas.criar"],
              },
            ],
          },
          {
            icon: "fa fa-users",
            title: "Usuários",
            exibir: true,
            children: [
              {
                title: "Ver Professores",
                to: { name: "professores" },
                icon: "fa fa-user-tie",
                permissions: ["users.*"],
              },
              {
                title: "Outros",
                to: { name: "usuarios" },
                icon: "fa fa-bookmark",
                permissions: ["users.*"],
              },
            ],
          },
          {
            icon: "fa fa-money",
            title: "Financeiro",
            exibir: exibirFinanceiro,
            children: [
              {
                title: "Mensalidades",
                to: { name: "mensalidades" },
                permissions: "financeiro.baixarParcela",
                icon: "",
              },
              {
                title: "Gestão de Caixas",
                to: { name: "caixas" },
                permissions: "caixas.abrir",
                icon: "",
              },
            ],
          },
          {
            icon: "mdi-account-network",
            title: "Administração",
            exibir: true,
            children: [
              {
                title: "Dashboard",
                to: { name: "admin" },
                icon: "",
              },
              {
                title: "Franquias",
                to: { name: "franquias" },
                permissions: ["franquias.*", "franquias.criarFranquia"],
                icon: "",
              },
              // {
              //   title: "Inep",
              //   to: { name: "inep" },
              //   icon: "",
              // },
            ],
          },
        ],
        width: "250px",
      },
    };
  },
  methods: {
    toggleMenu() {
      this.$store.commit("MainTemplate/setMenuOpen", !this.menuOpen);
    },
    dataInicial() {
      const data = Date.now();
      const dataConvertida = new Date(data);
      // dataConvertida.setTime(0);
      dataConvertida.setHours(0);
      dataConvertida.setMinutes(0);
      dataConvertida.setSeconds(0);
      // estabeler temporizador
      dataConvertida.setMinutes(this.durabilidadeDaVidaDoUsuario);
      dataConvertida.setHours(2);
      this.tempo = dataConvertida;
    },
    // temporizador() {
    //   this.idInterval = setInterval(() => {
    //     const data = new Date(this.tempo);
    //     data.setSeconds(data.getSeconds() - 1);
    //     this.tempo = data.toISOString();
    //     const h = this.zeroFill(data.getHours());
    //     const m = this.zeroFill(data.getMinutes());
    //     const s = this.zeroFill(data.getSeconds());
    //     this.tempoFormatado = `${h}:${m}:${s}`;
    //     const somaTempo = parseInt(
    //       this.zeroFill(data.getHours()) +
    //         this.zeroFill(data.getMinutes()) +
    //         this.zeroFill(data.getSeconds()),
    //       10
    //     );
    //     if (parseInt(h, 10) === 0 && parseInt(m, 10) === 0 && parseInt(s, 10) === 0) {
    //       this.$router.push({ name: "logout" });
    //       return;
    //     }
    //     if (!somaTempo) {
    //       this.limparIntervalo(this.idInterval);
    //     }
    //     if (parseInt(h, 10) === 0 && parseInt(m, 10) === 10 && parseInt(s, 10) === 0) {
    //       this.modalOcioso = true;
    //     }
    //   }, 1000);
    // },
    limparIntervalo(idInterval) {
      clearInterval(idInterval);
    },
    zeroFill(n) {
      return n <= 9 ? `0${n}` : `${n}`;
    },
  },
};
</script>
<style>
.lineUp {
  animation: 3s anim-lineUp ease-out infinite;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  15% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
</style>
