<template>
  <main-template hide-franquia>
    <v-row class="mx-3">
      <v-col cols="12" class="mx-1">
        <v-img
          src="/img/portal_aluno.svg"
          style="max-width: 100%; height: auto"
        />
      </v-col>
      <v-col cols="12" class="mx-1">
        <small>Bem-vindo(a)!</small>
        <h1>{{ this.nomeAluno }}</h1>
        <div class="my-1 d-flex align-center">
          <span v-for="matricula in historicoAluno" :key="matricula.id">
            <v-btn
              x-large
              color="success"
              dark
              class="mr-3"
              @click="downloadContrato(matricula.id)"
            >
              Contrato
            </v-btn>
            <v-btn
              x-large
              color="success"
              dark
              class="mr-3"
              @click="downloadBoletim(matricula.id)"
            >
              Histórico
            </v-btn>

            <v-btn
              x-large
              color="success"
              dark
              class="mr-3"
              @click="() => carteira(matricula.id)"
            >
              Carteirinha
            </v-btn>

            <v-btn
              x-large
              color="success"
              dark
              class="mr-3"
              @click="abrirModalEscolhaDisciplina(matricula.id)"
            >
              Material da disciplina
            </v-btn>
          </span>
        </div>
      </v-col>
    </v-row>

    <v-col col="12">
      <v-alert
        dismissible
        v-if="message"
        outlined
        type="error"
        prominent
        border="left"
      >
        {{ message }}
      </v-alert>
      <h2>Minhas Matrículas</h2>
      <v-simple-table class="mt-3">
        <template v-slot:default>
          <tbody>
            <tr v-for="matricula in historicoAluno" :key="matricula.id">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr class="blue lighten-1">
                      <td class="white--text">{{ matricula.id }}</td>
                      <td class="white--text">
                        {{ matricula.ano ? matricula.ano.descricao : "" }}
                      </td>
                      <td class="white--text">
                        {{
                          matricula.turma
                            ? matricula.turma.franquia.descricao
                            : ""
                        }}
                      </td>
                      <td class="white--text">
                        {{
                          matricula.serie ? matricula.serie.descricao : "- - -"
                        }}
                      </td>
                      <td>
                        <v-chip class="ma-2" color="green" text-color="white">
                          {{
                            matricula.turma
                              ? matricula.turma.descricao
                              : "- - -"
                          }}
                        </v-chip>
                      </td>

                      <td width="20%" v-if="matricula.turma">
                        <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="
                                parseInt(matricula.turma.curso_id, 10) !== 10
                              "
                              x-small
                              class="ml-1"
                              color="blue-grey lighten-3"
                              @click="boletim(matricula.id)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small class="ml-1">fa fa-file </v-icon>
                            </v-btn>
                          </template>
                          <span>Boletim</span>
                        </v-tooltip>
                        <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              class="ml-1"
                              v-if="
                                parseInt(matricula.turma.curso_id, 10) === 10
                              "
                              color="blue-grey lighten-3"
                              @click="relatorioBncc(matricula.id)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small class="ml-1">fa fa-file </v-icon>
                            </v-btn>
                          </template>
                          <span>Relatório BNCC</span>
                        </v-tooltip>
                        <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              x-small
                              class="ml-1"
                              color="blue-grey lighten-3"
                              v-bind="attrs"
                              v-on="on"
                              @click="openModalSelectMonths(matricula.id)"
                            >
                              <v-icon small class="ml-1"
                                >fa fa-address-book
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Frequência</span>
                        </v-tooltip>
                        <v-tooltip color="blue-grey lighten-3" bottom offset-x>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-if="
                                parseInt(matricula.turma.curso_id, 10) !== 10
                              "
                              x-small
                              class="ml-1"
                              color="blue-grey lighten-3"
                              @click="desempenhoDoAluno(matricula.id)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small class="ml-1"
                                >fa fa-bar-chart</v-icon
                              >
                            </v-btn>
                          </template>
                          <span>Desempenho</span>
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6">
                        <h4 class="my-3">
                          Prestações desta matricula: #{{ matricula.id }}
                        </h4>
                        <v-simple-table height="300px">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th class="text-left">#</th>
                                <th class="text-left">Referência</th>
                                <th class="text-left">Situação</th>
                                <th class="text-left">Valor</th>
                                <th class="text-left">Ações</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                v-for="parcela in matricula.parcelas"
                                :key="parcela.id"
                              >
                                <td>#{{ parcela.id }}</td>
                                <td>{{ parcela.referencia }}</td>
                                <td>
                                  <v-chip
                                    class="ma-2"
                                    text-color="white"
                                    :class="{
                                      green:
                                        parcela.situacao_referencia ===
                                        'À vencer',
                                      red:
                                        parcela.situacao_referencia ===
                                        'Vencido(a)',
                                      blue:
                                        parcela.situacao_referencia ===
                                        'Pago(a)',
                                      orange:
                                        parcela.situacao_referencia ===
                                        'Vence hoje',
                                    }"
                                  >
                                    {{ parcela.situacao_referencia }}
                                  </v-chip>
                                </td>
                                <td>{{ parcela.valor | currency }}</td>
                                <td>
                                  <v-btn
                                    :disabled="
                                      parcela.situacao_referencia ===
                                        'Vencido(a)' ||
                                      parcela.situacao_referencia === 'Pago(a)'
                                    "
                                    @click="gerarPagamento(parcela)"
                                  >
                                    Pagar agora
                                  </v-btn>

                                  <v-tooltip bottom color="primary">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-show="
                                          parcela.situacao_referencia ===
                                          'Vencido(a)'
                                        "
                                        class="mx-2"
                                        fab
                                        dark
                                        v-bind="attrs"
                                        v-on="on"
                                        x-small
                                        color="primary"
                                      >
                                        <v-icon dark> mdi-help </v-icon>
                                      </v-btn>
                                    </template>
                                    <span
                                      >Entre em contato com a instituição.</span
                                    >
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <template>
        <v-row justify="center">
          <v-dialog v-model="dialog_pagamento" persistent max-width="290">
            <v-card>
              <v-card-title class="text-h5 d-flex justify-center">
                Pagamento via PIX
              </v-card-title>
              <v-card-text v-if="!pagamentoEfetuado">
                <v-img
                  contain
                  lazy-src="https://picsum.photos/id/11/10/6"
                  max-height="150"
                  max-width="250"
                  :src="qrcode"
                ></v-img>
                <p class="text-caption mx-5 my-5">
                  <textarea
                    ref="copia_cola"
                    cols="27"
                    rows="7"
                    v-model="copia_cola"
                  ></textarea>
                </p>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    <v-btn
                      class=""
                      color="accent"
                      elevation="2"
                      small
                      @click="copiarCodigo()"
                    >
                      <v-icon right dark class="mr-2">
                        mdi-content-copy
                      </v-icon>

                      Copiar QR-CODE</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else cols="12">
                <v-row>
                  <v-col class="d-flex justify-center">
                    <v-icon
                      size="100"
                      class="animate__animated animate__zoomInDown green--text"
                      >mdi-check</v-icon
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="d-flex justify-center">
                    Pagamento efetuado com sucesso
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="green darken-1"
                  text
                  @click="
                    dialog_pagamento = false;
                    pagamentoEfetuado = false;
                  "
                >
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </v-col>
    <!-- {{   historicoAluno }} -->
    <!-- {{   disciplinas }} -->
    <!-- {{  matricula.turma.series[0].curso_id  }} -->
    <span v-for="matricula in historicoAluno" :key="matricula.id">

    <v-dialog v-model="mostrarModalEscolhaDisciplina" width="650">
      <v-card>
        <!-- {{ disciplinas }} -->
        <v-card-title>Escolha a disciplina</v-card-title>
        <v-card-text>
          <v-list-item
              v-for="disciplina in disciplinas"
              :key="disciplina.id"
              @click="navegarParaMaterialAulasPage(matricula.id, matricula.turma_id, disciplina.disciplina_id)"
          >
              <v-list-item-title>{{ disciplina.descricao }} &minus; {{ disciplina.disciplina_id }}</v-list-item-title>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-dialog></span>

    <ModalCarteira :dialog="dialog" :pdf="pdf" />
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ModalCarteira from "@/components/Matriculas/EModalCarteira.vue";
import MaterialDisciplinaPage from "../AcessoResponsavel/MaterialDisciplinaPage.vue";
import ModalEscolhaDisciplina from "@/components/EEscolhaDisciplina.vue";

export default {
  components: {
    ModalCarteira,
    MaterialDisciplinaPage,
    ModalEscolhaDisciplina,
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
  },
  data() {
    return {
      mostrarModalEscolhaDisciplina: false,
      gestaoDeAulasIdSelecionada: null,
      turmaIdSelecionada: null,
      nomeAluno: "",
      matriculaId: null,
      historicoAluno: [],
      pagamento: "",
      pagamentoEfetuado: false,
      message: null,
      dialog: false,
      pdf: null,
      matricula_selected: null,
      parcelas: [],
      dialog_pagamento: false,
      qrcode: null,
      copia_cola: null,
      isContract: false,
      turmaId: null,
      // disciplinaId: 12,
      disciplinas: [],
      dialog: false,
      table: {
        headers: [
          { text: "Nº", value: "numero" },
          { text: "Referência", value: "referencia" },
          { text: "Valor", value: "valor" },
          { text: "Valor Pago", value: "valorpago" },
          { text: "Valor Desconto", value: "valordesconto" },
          { text: "Valor Aberto", value: "valoraberto" },
          { text: "Situação", value: "situacao", align: "center" },
          { text: "Ações", value: "actions", sortable: false },
        ],
        headersComParam: [
          { text: "Nº", value: "numero" },
          { text: "Referência", value: "referencia" },
          { text: "Valor", value: "valor" },
        ],
      },
    };
  },
  async mounted() {
    await this.getMatriculaUsuarioAtivo();
    await this.matriculas();
    await this.pegarNomeAluno();
    await this.listarDisciplinas();
  },
  watch: {
    historicoAluno: {
      handler(val) {
        if (val === false) {
          this.pagamentoEfetuado = true;
        }
      },
      deep: true,
    },
    dialog(newVal) {
      this.internalDialog = newVal;
    },
  },
  methods: {
    openModalSelectMonths(matricula_id) {
      this.matricula_selected = matricula_id;
      this.dialog = !this.dialog;
    },
    async pegarNomeAluno() {
      this.nomeAluno = this.user.name.toLowerCase();
      const nome = this.nomeAluno.charAt(0).toUpperCase();
      this.nomeAluno = nome + this.nomeAluno.slice(1);
    },
    async matriculas() {
      const { aluno_id } = this.user;
      const response = await this.$services.matriculasService.historico(
        aluno_id
      );
      this.historicoAluno = response.data;

    },
    async boletim(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.responsavelAlunoService.boletim(
          parseInt(matricula_id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    copiarCodigo() {
      const payload = this.$refs.copia_cola.select();
      document.execCommand("copy");

    },
    gerarDadosPagamento(qrcode, copia_cola) {
      this.qrcode = `data:image/png;base64,${qrcode}`;
      this.copia_cola = copia_cola;
      this.dialog_pagamento = !this.dialog_pagamento;
    },
    async frenquenciaIndividual(mes) {
      this.$loaderService.open("Carregando dados...");
      try {
        await this.$services.relatoriosService.frenquenciaIndividual(
          parseInt(this.matricula_selected, 10),
          parseInt(mes.id, 10)
        );
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async relatorioBncc(matricula_id) {
      try {
        this.$loaderService.open("Carregando dados");
        await this.$services.responsavelAlunoService.relatorioBncc(
          parseInt(matricula_id, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$loaderService.close();
        this.message =
          "A turma não esta vinculado a um curso, entre em contato com a direção!";
      }
    },
    async desempenhoDoAluno(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      try {
        this.$router.push({
          name: "matriculas.desempenhoNota",
          params: { matricula_id },
        });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async gerarPagamento(parcela) {

      this.$loaderService.open("Gerando o pagamento...");
      try {
        if (parcela.qr_code_pix && parcela.copia_cola) {
          console.log("local");
          this.gerarDadosPagamento(parcela.qr_code_pix, parcela.copia_cola);
        } else {
          console.log("API");
          const resposta =
            await this.$services.responsavelAlunoService.getParcelaPix(
              parcela.id
            );
          this.pagamento = resposta.data;

          if (this.pagamento.PIX.success) {
            this.gerarDadosPagamento(
              this.pagamento.PIX.encodedImage,
              this.pagamento.PIX.payload
            );
          }
          this.matriculas();
        }
        this.verificarSePago(parcela.id);

        // this.parcelas.push(parcela); // Adicionar a parcela ao array de parcelas para exibição na tabela
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },

    async verificarSePago(parcela_id) {
      const intervaloId = setInterval(async () => {
        try {
          const resposta =
            await this.$services.responsavelAlunoService.estaPago(parcela_id);

          if (resposta.data !== "") {

            this.matriculas();
            this.pagamentoEfetuado = true;

            clearInterval(intervaloId);
          }
        } catch (erro) {
          clearInterval(intervaloId);
          console.error("Ocorreu um erro ao verificar o pagamento:", erro);
        }
      }, 1000);
    },
    async getMatriculaUsuarioAtivo() {
      try {
        const response =
          await this.$services.relatoriosService.getMatriculaContrato();
        const matricula_id = response.data.matricula.id;

        await this.verificarContrato(matricula_id);
      } catch (error) {
        this.$handleError(error);
      }
    },
    async verificarContrato(matricula_id) {
      try {
        const response =
          await this.$services.matriculasService.verificarContrato(
            matricula_id
          );
        if (response.situacao === false) {
          this.isContract = true;
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async contratoPrestacoes(matricula_id) {
      this.$loaderService.open("Baixando contrato de prestações");
      try {
        await this.$services.relatoriosService.contratoPrestacoes(matricula_id);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async downloadContrato(matriculaId) {
      this.$loaderService.open("Baixando contrato de prestações");
      try {
        await this.$services.relatoriosService.contratoPrestacoes(matriculaId);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async downloadBoletim(matriculaId) {
      this.$loaderService.open("Baixando histórico da matrícula");
      try {
        await this.$services.relatoriosService.boletimIndividual(matriculaId);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async carteira(matriculaId) {
      this.dialog = true;
      this.pdf = await this.$services.relatoriosService.exibirCarteira(
        matriculaId
      );
      this.pdf += "#zoom=150";
    },
    async abrirModalEscolhaDisciplina(matriculaId) {
      this.matriculaId = matriculaId;
      this.mostrarModalEscolhaDisciplina = true;
    },
    async navegarParaMaterialAulasPage(matriculaId, turmaId, disciplinaId) {
      // {{  matricula.turma.series[0].curso_id  }}/
      // console.log(`Curso_id: ${matricula.turma.series[0].curso_id}`);
      // console.log(`Matricula: ${matriculaId}, Turma: ${turmaId}, Disciplina: ${disciplinaId}`);
      this.$router.push({
        name: 'acessoResponsavel.materialDisciplina',
        params: {
          matriculaId: matriculaId,
          turmaId: turmaId,
          disciplinaId: disciplinaId,
        }
      });
      // Fecha o modal após a navegação
      this.mostrarModalEscolhaDisciplina = false;
    },


    async listarDisciplinas() {
    try {

      if (this.historicoAluno.length > 0) {

        const matricula = this.historicoAluno[this.historicoAluno.length - 1];

        const response = await this.$services.disciplinasService.getDisciplinasDoCurso(matricula.turma.series[0].curso_id);

        this.disciplinas = response;
      } else {

        console.error('Nenhum histórico de aluno encontrado.');
      }
    } catch (error) {
     
      console.error('Erro ao obter disciplinas:', error);
    }
  },




  },
};
</script>
<style scoped>
td {
  padding-left: 0px;
}
</style>





