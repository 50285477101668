<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'alunos' })"
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }}&nbsp;Aluno
        </h1>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-tabs
                v-model="tab"
                background-color="deep-purple accent-4"
                centered
                dark
                icons-and-text
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab href="#tab-1">
                  Aluno
                  <v-icon>fa-graduation-cap</v-icon>
                </v-tab>

                <v-tab href="#tab-2">
                  Saúde
                  <v-icon>fa-solid fa-notes-medical</v-icon>
                </v-tab>

                <v-tab href="#tab-3">
                  Endereço do Aluno
                  <v-icon>fa-address-card</v-icon>
                </v-tab>

                <v-tab href="#tab-4">
                  Responsável Financeiro
                  <v-icon>mdi-account-box</v-icon>
                </v-tab>
                <v-tab href="#tab-5">
                  Endereço do Responsável
                  <v-icon>fa-address-card</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item :value="'tab-1'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <template>
                            <!--UPLOAD-->
                            <form
                              enctype="multipart/form-data"
                              novalidate
                              v-if="isInitial || isSaving"
                            >
                              <h4>Carregar imagem</h4>
                              <div class="dropbox">
                                <input
                                  type="file"
                                  multiple
                                  :name="uploadFieldName"
                                  @change="
                                    filesChange($event.target.files);
                                    fileCount = $event.target.files.length;
                                  "
                                  accept="image/*"
                                  class="input-file"
                                />
                                <p v-if="isInitial">
                                  Arraste uma imagem aqui para começar<br />
                                  ou clique para navegar
                                </p>
                                <v-col class="text-center" v-if="isSaving">
                                  <img class="text-center" width="150x" :src="base64Img" />
                                  <p>Imagem carregada!</p>
                                </v-col>
                              </div>
                            </form>
                          </template>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="8">
                          <ValidationProvider
                            name="Nome Completo do Aluno"
                            ref="nomecompleto"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Nome Completo</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-model="form.nomecompleto"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Nome social</e-label>
                          <v-text-field
                            placeholder="(Opcional)"
                            v-model="form.nome_social"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Código Único(INEP)</e-label>
                          <v-text-field v-model="form.codigo_inep" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Gênero</e-label>
                          <e-autocomplete
                            :items="$constants.sexoList"
                            :return-object="false"
                            :error-messages="errors"
                            :item-value="(value) => value.id"
                            :item-text="(value) => value.descricao"
                            v-model="form.sexo_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Cor/Raça</e-label>
                          <e-autocomplete
                            :items="$constants.corOuRacaList"
                            :return-object="false"
                            v-model="form.cor_ou_raca"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Religião</e-label>
                          <e-autocomplete
                            :items="$constants.religioesList"
                            :return-object="false"
                            v-model="form.religiao_id"
                            :item-text="(value) => value.descricao"
                            :item-value="(value) => value.id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Tipo sanguíneo</e-label>
                          <e-autocomplete
                            :items="$constants.tipoSanguineoList"
                            :return-object="false"
                            v-model="form.tiposanguineo_id"
                            :item-text="(value) => value.descricao"
                            :item-value="(value) => value.id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider
                            name="Data de nascimento"
                            rules="required"
                            ref="dataNascimento"
                            v-slot="{ errors }"
                          >
                            <e-label>Data de nascimento</e-label>
                            <e-date-picker
                              :error-messages="errors"
                              v-model="form.data_nascimento"
                              placeholder="dd/mm/yyyy"
                              dense
                              solo
                            ></e-date-picker>
                          </ValidationProvider>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Civil</e-label>
                          <e-autocomplete
                            :items="$constants.estadoCivilList"
                            :return-object="false"
                            :item-text="(value) => value.descricao"
                            :item-value="(value) => value.id"
                            v-model="form.estadocivil_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>País de Nascimento</e-label>
                          <e-autocomplete
                            :items="paisesNascimento"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="form.nacionalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <!-- Campo para digitar o país (aparece apenas se 'Outra Nacionalidade' for selecionada) -->
                        <v-col
                          v-if="form.nacionalidade === 'Outra Nacionalidade'"
                          class="pt-0 pb-0"
                          cols="3"
                        >
                          <e-label>País (Outra Nacionalidade)</e-label>
                          <v-text-field v-model="form.outroPaisNascimento" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>UF de nascimento aluno</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.id"
                            v-model="form.estadonasc_id"
                            @change="(event) => atualizarEstado(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Local de nascimento</e-label>
                          <e-autocomplete
                            :items="municipiosNasc"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.id"
                            v-model="form.municipio_nas"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Número do CPF</e-label>
                          <v-text-field v-model="form.cpf" v-mask="'###.###.###-##'" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>RG</e-label>
                          <v-text-field
                            v-mask="'##.###.###-#'"
                            v-model="form.registrogeral"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Órgão Emissor</e-label>
                          <e-autocomplete
                            :items="$constants.orgaosEmissores"
                            :return-object="false"
                            :item-text="(item) => item.sigla + ' - ' + item.descricao"
                            :item-value="(item) => item.id"
                            v-model="form.orgaoemissor_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Emissor</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.descricao"
                            :item-value="(item) => item.id"
                            v-model="form.rgestado_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Data emissão</e-label>
                          <e-date-picker
                            v-model="form.rg_dataemissao"
                            dense
                            solo
                            placeholder="dd/mm/yyyy"
                          ></e-date-picker>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>(CEL)</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="form.celular_aluno_1"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col
                          :cols="selectedNacionalidade === 'Outra Nacionalidade' ? 6 : 9"
                          class="pt-0 pb-0"
                        >
                          <e-label>(CELULAR/ALUNO)</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="form.celular_aluno_2"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="6">
                          <!-- <ValidationProvider
                            name="Email do Aluno"
                            ref="emailAluno"
                            rules="required"
                            v-slot="{ errors }"
                          > -->
                          <!-- :error-messages="errors" -->
                          <e-label>Email</e-label>
                          <v-text-field
                            placeholder="email@email.com"
                            v-model="form.email"
                            dense
                            solo
                          />
                          <!-- </ValidationProvider> -->
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Contato para emergencias</e-label>
                          <v-text-field v-model="form.telefone1" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Certidão civil</e-label>
                          <v-text-field
                            v-mask="'######.##.##.####.#.#####.###.#######-##'"
                            v-model="form.matricula_certidao_de_nascimento"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Livro</e-label>
                          <v-text-field v-model="form.livro" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" col="3">
                          <e-label>Folha</e-label>
                          <v-text-field v-model="form.folha" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" col="3">
                          <e-label>Cartório</e-label>
                          <v-text-field v-model="form.cartorio_nascimento" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>UF cartório</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="form.estado_cartorio"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Observações</e-label>
                          <v-text-field v-model="form.observacoes_emergencia" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Observações Pessoais</e-label>
                          <v-text-field v-model="form.observacoes_pessoais" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Foi transferido?</e-label>
                          <e-autocomplete
                            :items="['Sim', 'Não']"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="form.foiTransferido"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Possui irmãos?</e-label>
                          <e-autocomplete
                            :items="['Sim', 'Não']"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="form.possui_irmaos"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col v-if="form.foiTransferido === 'Sim'" class="pt-0 pb-0" cols="6">
                          <e-label>Escola anterior</e-label>
                          <v-text-field v-model="form.escola_anterior" dense solo />
                        </v-col>
                        <v-col v-if="form.foiTransferido === 'Sim'" class="pt-0 pb-0" cols="6">
                          <e-label>Série anterior</e-label>
                          <v-text-field v-model="form.serie_anterior" dense solo />
                        </v-col>
                        <v-col v-if="form.foiTransferido === 'Sim'" class="pt-0 pb-0" cols="6">
                          <e-label>Ano Anterior</e-label>
                          <v-text-field v-model="form.ano_anterior" dense solo />
                        </v-col>
                      </v-row>

                      <v-row v-for="(elemento, index) in irmaos" :key="index" name="fade">
                        <v-col v-if="form.possui_irmaos === 'Sim'" class="pt-0 pb-0" cols="5">
                          <e-label>Irmão(a)</e-label>
                          <v-text-field v-model="elemento.nome" dense solo />
                        </v-col>

                        <v-col v-if="form.possui_irmaos === 'Sim'" class="pt-0 pb-0" cols="2">
                          <e-label>Curso:</e-label>
                          <v-text-field v-model="elemento.curso" dense solo />
                        </v-col>

                        <v-col v-if="form.possui_irmaos === 'Sim'" class="pt-0 pb-0" cols="2">
                          <e-label>Série</e-label>
                          <v-text-field v-model="elemento.serie" dense solo />
                        </v-col>

                        <v-col v-if="form.possui_irmaos === 'Sim'" class="pt-0 pb-0" cols="2">
                          <e-label>Idade</e-label>
                          <v-text-field v-model="elemento.idade" dense solo />
                        </v-col>

                        <tbody>
                          <tr>
                            <td class="custom-padding" style="text-align: center">
                              <!-- Centralizar conteúdo horizontalmente -->
                              <v-col
                                class="pt-0 pb-0"
                                cols="2"
                                v-if="form.possui_irmaos === 'Sim'"
                                align="center"
                              >
                                <!-- Centralizar conteúdo verticalmente -->
                                <v-tooltip v-if="!elemento.estaDeletado" color="error" bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                      x-small
                                      class="ml-1"
                                      color="error"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="removerIrmao(index)"
                                    >
                                      <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                  </template>
                                  <span>Apagar irmão(a)</span>
                                </v-tooltip>
                              </v-col>
                            </td>
                          </tr>
                        </tbody>
                      </v-row>

                      <v-row>
                        <v-col v-if="form.possui_irmaos === 'Sim'" cols="2">
                          <v-btn class="ml-3" color="secondary" @click="adicionarIrmao">
                            Adicionar irmão(a)
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col v-if="form.possui_irmaos === 'Sim'" cols="12"> </v-col>
                        <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Nome do Pai</e-label>
                          <v-text-field v-model="form.filiacao1" dense solo />
                        </v-col>

                        <v-col :cols="form.estado_vida_pai === 'Sim' ? 3 : 6" class="pt-0 pb-0">
                          <e-label>Vivo</e-label>
                          <e-autocomplete
                            :items="['Sim', 'Não']"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="form.estado_vida_pai"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col v-if="form.estado_vida_pai === 'Sim'" class="pt-0 pb-0" cols="3">
                          <e-label>Cel</e-label>
                          <v-text-field
                            v-model="form.celular_pai"
                            v-mask="`(##) # ####-####`"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Nome da Mãe</e-label>
                          <v-text-field
                            :error-messages="errors"
                            v-model="form.filiacao2"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col :cols="form.estado_vida_mae === 'Sim' ? 3 : 6" class="pt-0 pb-0">
                          <e-label>Vivo</e-label>
                          <e-autocomplete
                            :items="['Sim', 'Não']"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="form.estado_vida_mae"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col v-if="form.estado_vida_mae === 'Sim'" class="pt-0 pb-0" cols="3">
                          <e-label>Cel</e-label>
                          <v-text-field
                            v-mask="`(##) # ####-####`"
                            v-model="form.celular_mae"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Responsável pedagógico</e-label>
                          <v-text-field v-model="form.responsavelpedagogico" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Outros: (Informar nome e grau de parentesco)</e-label>
                          <v-text-field v-model="form.outros_parentes" dense solo />
                        </v-col>

                        <v-col cols="12">
                          <v-btn color="primary" @click="changeTab(2)" block>Proximo</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-2'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <h4>
                            DEFICIÊNCIA/TRANSTORNOS GLOBAIS DE DESENVOLVIMENTO/ALTAS HABILIDADES
                          </h4>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Tipo aluno(a)</e-label>
                          <e-autocomplete
                            :items="['Típico', 'Atípico']"
                            v-model="form.tipo_aluno"
                            label="Selecione uma opção"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col cols="8"></v-col>
                      </v-row>
                      <v-row>
                        <transition name="fade">
                          <v-col cols="12" class="pt-0 pb-0" v-if="form.tipo_aluno === 'Atípico'">
                            <v-row>
                              <v-col cols="6">
                                <e-label>Descrição:</e-label>
                                <v-text-field
                                  label="Descrição"
                                  v-model="form.diagnostico"
                                  dense
                                  solo
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </transition>
                      </v-row>
                      <!-- DADOS SOBRE A SAÚDE -->
                      <v-row>
                        <v-col class="pt-0 pb-0" cols="12">
                          <h4>DADOS SOBRE A SAÚDE</h4>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Tem alergia(s)?</e-label>
                          <v-combobox
                            v-model="selectedItemSaude"
                            :items="itemsAlergia"
                            label="Selecione uma opção"
                            dense
                            solo
                            @change="openTextFieldSaude"
                          >
                            <v-col>
                              <v-list-item-content>
                                <v-list-item-subtitle v-if="itemsAlergia === 'Sim'" />
                              </v-list-item-content>
                            </v-col>
                          </v-combobox>
                        </v-col>
                        <v-col cols="8"></v-col>
                        <transition name="fade">
                          <v-col cols="12" class="pt-0 pb-0" v-if="selectedItemSaude == 'Sim'">
                            <v-row>
                              <v-col cols="6">
                                <e-label>Tipos(s):</e-label>
                                <v-text-field
                                  label="Descrição"
                                  v-model="textFieldValueDetalhe"
                                  dense
                                  solo
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </transition>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(1)" block>Anterior</v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(3)" block>Próximo</v-btn>
                        </v-col>
                      </v-row>

                      <!-- DADOS SOBRE A SAÚDE -->
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-3'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <h4>DADOS SOBRE A ENDEREÇO DO(A) ALUNO(A)</h4>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>CEP</e-label>
                          <v-text-field
                            :error-messages="errors"
                            v-mask="`##.###-###`"
                            v-model="form.cep"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Endereço</e-label>
                          <v-text-field v-model="form.endereco" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label>Número</e-label>
                          <v-text-field v-mask="'#######'" v-model="form.numero" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Bairro</e-label>
                          <v-text-field v-model="form.bairro" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Complemento</e-label>
                          <v-text-field v-model="form.complemento" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label> País de residência </e-label>
                          <v-text-field v-model="form.pais_residencia" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label> Estado do aluno</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.id"
                            v-model="form.uf_residencia"
                            @change="(event) => atualizarEstadoAluno(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label> Cidade do aluno</e-label>
                          <e-autocomplete
                            :items="municipiosAluno"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="form.municipio_residencia"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Celular</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="form.telefone2"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label> Zona de Residência </e-label>
                          <e-autocomplete
                            :items="$constants.zonaResidenciaList"
                            :return-object="false"
                            v-model="form.zona_residencia"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Localização diferenciada de residência</e-label>
                          <e-autocomplete
                            :items="$constants.localizacaoDiferenciadaDeResidenciaList"
                            :return-object="false"
                            v-model="form.zona_diferenciada_residencia"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col cols="4">
                          <v-btn color="primary" @click="changeTab(2)" block>Anterior</v-btn>
                        </v-col>
                        <v-col cols="4">
                          <v-btn
                            @click="copiarEnderecoAluno"
                            :class="{ 'green--text': copiando }"
                            block
                          >
                            {{
                              copiando
                                ? "Dados copiados com sucesso!"
                                : "Copiar Endereço do Aluno para o Responsável"
                            }}
                          </v-btn>
                        </v-col>
                        <v-col cols="4">
                          <v-btn color="primary" @click="changeTab(4)" block>Próximo</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-4'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <e-label>Buscar Responsável</e-label>
                          <v-autocomplete
                            :items="responsaveis"
                            :item-text="
                              (responsavel) =>
                                `${responsavel.cpf} - ${responsavel.descricao}`.toUpperCase()
                            "
                            :item-value="responsavel.cpf"
                            label="Pesquise aqui um responsável já cadastrado no sistema"
                            return-object
                            dense
                            cache-items
                            hide-no-data
                            solo
                            v-model="responsavel_selecionado"
                            @change="selecionarResponsavel"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <!-- <v-col class="pt-0 pb-0" cols="3">
                          <e-label>&nbsp;</e-label>
                          <v-btn @click="repetirDadosAluno()" color="light-green" block>
                            Repetir dados do Aluno
                          </v-btn>
                        </v-col> -->
                        <v-col class="pt-0 pb-0" cols="12">
                          <ValidationProvider
                            name="Nome Completo do Responsavel"
                            ref="nomeResponsavel"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>Responsavel Financeiro</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-model="responsavel.descricao"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Nome social</e-label>
                          <v-text-field
                            placeholder="(Opcional)"
                            v-model="responsavel.nome_social"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Tipo Pessoa</e-label>
                          <e-autocomplete
                            :items="$constants.tipoPessoa"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="responsavel.tipo_pessoa"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="4">
                          <e-label>Profissão</e-label>
                          <v-text-field
                            placeholder="(Opcional)"
                            v-model="responsavel.profissao"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <ValidationProvider
                            name="CPF do Responsavel"
                            rules="required"
                            ref="cpfResponsavel"
                            v-slot="{ errors }"
                          >
                            <e-label>CPF</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-mask="'###.###.###-##'"
                              v-model="responsavel.cpf"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>RG</e-label>
                          <v-text-field v-model="responsavel.rg" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Órgão Emissor</e-label>
                          <e-autocomplete
                            :items="$constants.orgaosEmissores"
                            :return-object="false"
                            :item-text="(item) => item.sigla + ' - ' + item.descricao"
                            :item-value="(item) => item.id"
                            v-model="responsavel.orgaoemissor_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Estado Emissor</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.descricao"
                            :item-value="(item) => item.id"
                            v-model="responsavel.estadoemissor_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Data emissão</e-label>
                          <v-text-field
                            v-model="responsavel.data_expedicao"
                            v-mask="'##/##/####'"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="6">
                          <e-label>Estado Civil</e-label>
                          <e-autocomplete
                            :items="$constants.estadoCivilList"
                            :return-object="false"
                            :item-text="(value) => value.descricao"
                            :item-value="(value) => value.id"
                            v-model="responsavel.estadocivil_id"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <!-- <v-col class="pt-0 pb-0" cols="2">
                          <e-label>UF de nascimento responsável</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.codigo"
                            v-model="responsavel.estadualidade"
                            @change="(event) => atualizarEstadoResponsavel(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label>País de Nascimento</e-label>
                          <e-autocomplete
                            :items="paisesNascimento"
                            :return-object="false"
                            :item-text="(value) => value"
                            :item-value="(value) => value"
                            v-model="responsavel.nacionalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Município de nascimento responsável</e-label>
                          <e-autocomplete
                            :items="municipiosRes"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="responsavel.municipalidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>

                        <v-col
                          v-if="responsavel.nacionalidade === 'Outra Nacionalidade'"
                          class="pt-0 pb-0"
                          cols="12"
                        >
                          <e-label>País (Outra Nacionalidade)</e-label>
                          <v-text-field v-model="responsavel.outroPaisNascimento" dense solo />
                        </v-col> -->
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(3)" block>Anterior</v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(5)" block>Próximo</v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'tab-5'">
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <h4>ENDEREÇO DO(A) RESPONSÁVEL DO(A) ALUNO(A)</h4>
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="2">
                          <ValidationProvider
                            name="CEP do Responsável"
                            ref="cepResponsavel"
                            rules="required"
                            v-slot="{ errors }"
                          >
                            <e-label>CEP</e-label>
                            <v-text-field
                              :error-messages="errors"
                              v-mask="`##.###-###`"
                              v-model="responsavel.cep"
                              dense
                              solo
                            />
                          </ValidationProvider>
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Endereço</e-label>
                          <v-text-field v-model="responsavel.logradouro" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label>Número</e-label>
                          <v-text-field
                            v-mask="'#######'"
                            v-model="responsavel.numero"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>Bairro</e-label>
                          <v-text-field v-model="responsavel.bairro" dense solo />
                        </v-col>

                        <v-col class="pt-0 pb-0" cols="5">
                          <e-label>Complemento</e-label>
                          <v-text-field v-model="responsavel.complemento" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label> País de residência </e-label>
                          <v-text-field v-model="responsavel.pais" dense solo />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="2">
                          <e-label> Estado responsável</e-label>
                          <e-autocomplete
                            :items="$constants.estadosList"
                            :return-object="false"
                            :item-text="(item) => item.codigo"
                            :item-value="(item) => item.id"
                            v-model="responsavel.estado"
                            @change="(event) => atualizarEstadoResponsavel2(event)"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <!-- <v-col v-if="form.municipio_residencia" class="pt-0 pb-0" cols="3">
                          <e-label>Cidade</e-label>
                          <v-text-field v-model="responsavel.cidade" dense solo />
                        </v-col> -->

                        <!-- Se form.municipio_residencia não estiver preenchido, mostra e-autocomplete -->
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label> Cidade do responsável</e-label>
                          <e-autocomplete
                            :items="municipiosRes2"
                            :return-object="false"
                            :item-text="(item) => item"
                            :item-value="(item) => item"
                            v-model="responsavel.cidade"
                            label="Selecione uma opção"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="6">
                          <!-- <ValidationProvider
                            name="Email do Responsável"
                            ref="emailResponsavel"
                            rules="required"
                            v-slot="{ errors }"
                          > -->
                          <!-- :error-messages="errors" -->
                          <e-label>Email</e-label>
                          <v-text-field
                            placeholder="email@email.com"
                            v-model="responsavel.email"
                            dense
                            solo
                          />
                          <!-- </ValidationProvider> -->
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>CEL 1:</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="responsavel.celular1"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col class="pt-0 pb-0" cols="3">
                          <e-label>CEL 2:</e-label>
                          <v-text-field
                            v-mask="'(##) # ####-####'"
                            v-model="responsavel.celular2"
                            dense
                            solo
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-btn color="primary" @click="changeTab(4)" block>Anterior</v-btn>
                        </v-col>
                        <v-col cols="6">
                          <v-btn
                            color="primary"
                            :disabled="submittingForm"
                            :loading="submittingForm"
                            type="submit"
                            @click="myFunctionToValidateFields()"
                            block
                            >Enviar Dados</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>
        </v-row>
      </form>
    </ValidationObserver>
  </main-template>
</template>

<script>
// import { mapActions, mapGetters } from "vuex";
import { mapGetters } from "vuex";
import { $toast } from "vuetify-snackbar-toast";
import { ref } from "vue";
import cloneDeep from "lodash/cloneDeep";
import ELabel from "../../components/ELabel.vue";

const STATUS_INITIAL = 0;
const STATUS_SAVING = 1;
const STATUS_SUCCESS = 2;
const STATUS_FAILED = 3;
export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    "form.nacionalidade": function (newVal) {
      this.selectedNacionalidade = newVal;
    },
    form: {
      nacionalidade: "",
      celular_aluno_2: "",
      estado_vida_pai: "",
      celular_pai: "",
      estado_vida_mae: "",
      celular_mae: "",
      deep: true,
      handler() {
        // if (formValue) {
        //   this.recursos_saeb = formValue.recursos_saeb;
        // } else {
        //   this.recursos_saeb = [];
        // }
        if (this.nextInepTimeoutCall) {
          clearTimeout(this.nextInepTimeoutCall);
        }
        this.nextInepTimeoutCall = setTimeout(() => {
          this.verificarCodigoINEP();
        }, 500);
      },
    },

    // AÇÃO PEDIDA PARA SER DESABILITADA
    // "responsavel.cep": {
    //   handler(oldValue, newValue) {
    //     if (this.responsavel.cep.length === 10) {
    //       this.$loaderService.open("Consultando CEP");
    //       fetch(
    //         `https://brasilapi.com.br/api/cep/v2/${this.responsavel.cep
    //           .replace(".", "")
    //           .replace("-", "")}`
    //       )
    //         .then((response) => {
    //           if (response.status !== 200) {
    //             this.$toast.danger("O CEP informádo é inválido");
    //           }
    //           response.json().then((data) => {
    //             // console.log(data.neighborhood);
    //             this.responsavel.logradouro = data.street;
    //             this.responsavel.bairro = data.neighborhood;
    //             this.responsavel.estado = data.state;
    //             this.responsavel.cidade = data.city;
    //             this.responsavel.pais = "Brasil";
    //             // console.log(data)
    //           });
    //         })
    //         .finally(() => this.$loaderService.close());
    //       // let novocep = this.responsavel.cep.replace(".", "").replace("-", "");
    //     }
    //   },
    //   deep: true,
    // },
  },
  computed: {
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
    // ...mapGetters("Alunos", ["alunosList", "carregandoAlunos"]),
    // ...mapGetters("Alunos", ["alunos"]),
    ...mapGetters("Auth", ["user"]),
    checkboxLabel() {
      return this.selected ? "Opção selecionada" : "Selecione a opção";
    },
  },
  mounted() {
    this.reset();
    this.loadFormData();
    this.loadResponsaveis();
  },

  data() {
    return {
      responsavel_selecionado: null,
      responsaveis: [],
      selectedNacionalidade: "",
      submittingForm: false,
      recursos_saeb: [],
      form: {
        naturalidade: "Brasileira",
        municipio_residencia: null,
        paisNascimento: null,
        outroPaisNascimento: null,
      },
      paisesNascimento: ["Brasil", "Outra Nacionalidade"],
      responsavel: {
        descricao: " ",
        cidade: null,
      },
      irmao: {
        nome: "",
        curso: "",
        serie: "",
        idade: "",
      },
      irmaos: [],
      copiando: false,
      nextInepTimeoutCall: null,
      pesquisandoINEP: false,
      municipiosNasc: [],
      municipiosRes: [],
      municipiosRes2: [],
      municipiosAluno: [],
      edit: true,
      imageAluno: null,
      countImagem: false,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      tab: "",
      podeEnviarFormulario: [],
      selected: [],
      has_autismo: false,
      has_defMental: false,
      has_defFisica: false,
      openDescriptionSelectionAutismo: false,
      openDescriptionSelectionDefMent: false,
      openDescriptionSelectionDefFis: false,
      textFieldValueDes: "",
      textFieldValueAutismo: "",
      textFieldValueDefMent: "",
      textFieldValueDefFis: "",
      textFieldValueDetalhe: "",
      textFieldValueDetalheAtipico: "",
      textFieldValueMed: "",
      textFieldValueAli: "",
      textFieldValueObsEx: "",
      selectedItem: null,
      selectedItemSaude: null,
      // tipo_aluno: null,
      itemsAlergia: ["Sim", "Não"],
      // itemsTipoAluno: ["Típico", "Atípico"],
      textFieldOpen: false,
      textFieldOpenTipoAluno: false,
      enabled: false,
    };
  },

  methods: {
    selecionarResponsavel() {
      this.responsavel = this.responsavel_selecionado;
    },

    async loadResponsaveis() {
      try {
        const data = await this.$services.alunosService.getResponsaveisFranquia();
        this.responsaveis = data;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    changeTab(tabindex) {
      this.tab = `tab-${tabindex}`;
    },

    myFunctionToValidateFields() {
      this.podeEnviarFormulario = [];
      this.validateField("cepResponsavel", 5);
      this.validateField("emailResponsavel", 3);
      this.validateField("cpfResponsavel", 4);
      this.validateField("nomeResponsavel", 4);
      this.validateField("cepAluno", 3);
      this.validateField("emailAluno", 1);
      this.validateField("filiacao2", 1);
      this.validateField("genero", 1);
      this.validateField("nomecompleto", 1);
      this.validateField("dataNascimento", 1);
      // this.validateField('nomeirmao', 4);

      if (!this.podeEnviarFormulario.includes(false)) {
        this.submitForm();
      }
    },
    async copiarEnderecoAluno() {
      this.responsavel.cep = cloneDeep(this.form.cep);
      this.responsavel.logradouro = cloneDeep(this.form.endereco);
      this.responsavel.numero = cloneDeep(this.form.numero);
      this.responsavel.bairro = cloneDeep(this.form.bairro);
      this.responsavel.complemento = cloneDeep(this.form.complemento);
      this.responsavel.pais = cloneDeep(this.form.pais_residencia);
      this.responsavel.estado = cloneDeep(this.form.uf_residencia);
      if (this.form.municipio_residencia) {
        // Se houver, copie para responsavel.cidade
        this.responsavel.cidade = cloneDeep(this.form.municipio_residencia);
      } else {
        // Caso contrário, deixe responsavel.cidade como está ou defina para um valor padrão, se aplicável.
        // Exemplo: this.responsavel.cidade = "Cidade Padrão";
      }

      this.copiando = true;
      setTimeout(() => {
        // Lógica de cópia real aqui
        this.copiando = false;
      }, 4000);
    },
    async validateField(field, tabindex) {
      const provider = this.$refs[field];
      if (provider) {
        provider.validate().then((response) => {
          if (!response.valid) {
            this.$toast.danger(response.errors[0]);
            this.changeTab(tabindex);
          }
          this.podeEnviarFormulario.push(response.valid);
        });
      }
    },
    // ...mapActions("Alunos", ["loadAlunos"]),
    atualizarEstado(value) {
      if (value) {
        const uf = this.$constants.estadosList.filter((item) => item.id === value);
        this.municipiosNasc = this.$constants.municipios[uf[0]?.codigo]?.cidades;
      }
    },
    atualizarEstadoResponsavel(value) {
      if (value) {
        const uf = this.$constants.estadosList.filter((item) => item.id === value);
        this.municipiosRes = this.$constants.municipios[uf[0]?.codigo]?.cidades;
      }
    },
    atualizarEstadoResponsavel2(value) {
      if (value) {
        const uf = this.$constants.estadosList.filter((item) => item.id === value);
        this.municipiosRes2 = this.$constants.municipios[uf[0]?.codigo]?.cidades;
      }
    },
    atualizarEstadoAluno(value) {
      if (value) {
        const uf = this.$constants.estadosList.filter((item) => item.id === value);
        this.municipiosAluno = this.$constants.municipios[uf[0]?.codigo]?.cidades;
      }
    },
    // repetirDadosAluno(){
    //   this.form.nome_completo_responsavel = this.form.nomecompleto;
    //   console.log("Nome completo: " + this.form.nome_completo_responsavel);
    // },

    async getResponsavel(responsavel_id) {
      try {
        const aluno = await this.$services.alunosService.getResponsavel(responsavel_id);
        return aluno;
      } catch (error) {
        return this.$handleError(error);
      }
    },

    async pegarAluno() {
      try {
        const { aluno_id } = this.$route.params;
        const aluno = await this.$services.alunosService.pegarAluno(aluno_id);
        return aluno;
      } catch (error) {
        return this.$handleError(error);
      }
    },
    adicionarIrmao() {
      this.irmaos.push({
        nome: "",
        curso: "",
        serie: "",
        idade: "",
      });
    },

    removerIrmao(index) {
      this.irmaos.splice(index, 1);
    },

    async submitForm() {
      this.form.especial = Boolean(this.form.especial);
      this.form.recursos_saeb = this.recursos_saeb;
      this.submittingForm = true;

      this.handleHealthlyFields();

      try {
        if (this.responsavel) {
          const response = await this.$services.alunosService.criaOuAtualizaResposavel(
            this.responsavel
          );
          this.form.responsavel_id = response;
        }
      } catch (error) {
        this.submittingForm = false;
        if (error.error.status) {
          this.$toast.danger(error.error.message);
          return;
        }
        this.$toast.danger("Os dados informados estão invalidos");
      }
      this.form.irmaos = this.irmaos;
      if (this.editing) {
        try {
          const response = await this.$services.alunosService.atualizarAluno(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Aluno(a) Atualizado(a) com sucesso");
          this.$router.push({ name: "alunos" });
        } catch (error) {
          this.submittingForm = false;
          this.$toast.danger(error.error.message);
          return;
        }
      } else {
        try {
          const response = await this.$services.alunosService.criarAluno(this.form);
          await this.uploadImage(response.id);
          this.$toast.success("Aluno criado com sucesso");
          this.$router.push({ name: "alunos" });
        } catch (error) {
          this.submittingForm = false;
          if (error.error.status) {
            this.$toast.danger(error.error.message);
            return;
          }
          this.$toast.danger("Os dados informados estão invalidos");
          return;
        }
      }
      this.submittingForm = false;
    },

    handleHealthlyFields() {
      this.form.tipos_de_transtornos = this.selected;
      if (this.has_autismo) {
        this.form.descricao_autismo = this.textFieldValueAutismo;
      } else {
        this.form.has_autismo = Boolean(this.has_autismo);
        this.form.descricao_autismo = " ";
      }
      this.form.has_autismo = this.has_autismo;
      if (this.has_defMental) {
        this.form.descricao_defMental = this.textFieldValueDefMent;
      } else {
        this.form.has_defMental = Boolean(this.has_defMental);
        this.form.descricao_defMental = " ";
      }
      this.form.has_defMental = this.has_defMental;
      if (this.has_defFisica) {
        this.form.descricao_defFisica = this.textFieldValueDefFis;
      } else {
        this.form.has_defFisica = Boolean(this.has_defFisica);
        this.form.descricao_defFisica = " ";
      }
      this.form.has_defFisica = this.has_defFisica;
      if (this.selectedItemSaude === "Sim") {
        this.form.saude_detalhe = this.textFieldValueDetalhe;
      } else {
        this.form.saude_detalhe = " ";
      }
      this.form.selectedItemSaude = this.selectedItemSaude;

      // if (this.tipo_aluno === "Atípico") {
      //   this.form.diagnostico = this.textFieldValueDetalheAtipico;
      // } else {
      //   this.form.diagnostico = " ";
      // }
      // this.form.tipo_aluno = this.tipo_aluno;
    },

    async loadFormData() {
      const aluno = await this.pegarAluno();
      if (aluno) {
        this.edit = false;
        aluno.irmaos.map((item) => this.irmaos.push(item));
      }
      const responsavel = await this.getResponsavel(aluno.responsavel_id);
      // this.atualizarEstadoAluno(aluno.uf_residencia);
      // this.atualizarEstado(aluno.estadualidade);
      if (aluno.imagem) {
        this.currentStatus = STATUS_SAVING;
      }
      this.recursos_saeb = aluno.recursos_saeb ?? [];
      this.form = {
        ...aluno,
      };
      this.responsavel = {
        ...responsavel[0],
      };
      this.responsavel.estadoemissor_id = parseInt(this.responsavel.estadoemissor_id, 10);

      this.atualizarEstadoResponsavel(this.responsavel.estadualidade);

      const uf = this.$constants.estadosList.filter((item) => item.id == this.responsavel.estado);
      this.responsavel.estado = uf[0].id;
      this.atualizarEstadoResponsavel2(uf[0].id);

      const uf_aluno = this.$constants.estadosList.filter((item) => item.id == aluno.uf_residencia);
      this.form.uf_residencia = uf_aluno[0].id;
      this.atualizarEstadoAluno(uf_aluno[0].id);

      const uf_aluno_nasc = this.$constants.estadosList.filter(
        (item) => item.id == aluno.estadonasc_id
      );
      this.form.municipio_nas = aluno.municipio_nas;
      this.atualizarEstado(uf_aluno_nasc[0].id);

      this.textFieldValueDetalhe = aluno.saude_detalhe;
      this.textFieldValueDetalheAtipico = aluno.diagnostico;

      if (this.selectedItemSaude === "Sim") {
        this.selectedItemSaude = aluno.selectedItemSaude;
      }
      this.selectedItemSaude = aluno.selectedItemSaude;

      if (this.tipo_aluno === "Atípico") {
        aluno.especial = true;
        this.tipo_aluno = "Atípico";
      } else {
        aluno.especial = false;
        this.tipo_aluno = "Típico";
      }
    },

    async uploadImage(id) {
      try {
        await this.$services.imageService.uploadImage("alunos/upload-image", this.imageAluno, id);
      } catch (error) {
        this.$handleError(error);
      }
    },

    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    filesChange(fileList) {
      // handle file changes
      if (!fileList.length) return;

      // append the files to FormData
      if (fileList.length > 1) {
        this.countImagem = true;
        setTimeout(() => {
          this.countImagem = false;
        }, 4000);
      }
      Array.from(Array(fileList.length).keys()).map((x) => {
        this.imageAluno = fileList[x];
        return fileList[x];
      });

      this.currentStatus = STATUS_SAVING;
    },

    async verificarCodigoINEP() {
      if (this.editing || !this.form.codigo_inep) return;
      this.pesquisandoINEP = true;
      try {
        const aluno = await this.$services.alunosService.getAlunoComINEP(this.form.codigo_inep);
        if (aluno) {
          if (aluno.configuracao_id !== this.user.configuracao_id) {
            this.$modals.warn({
              title: "Você gostaria de transferir esse(a) aluno(a)?",
              message: `Encontramos o(a) aluno(a) <strong>${aluno.nomecompleto}</strong> com INEP <strong>${aluno.codigo_inep}</strong> registrado em outra escola, você gostaria de transferí-lo para sua escola?`,
              buttons: [
                {
                  text: "Cancelar",
                  callback: () => {
                    this.$modals.close();
                  },
                  props: {
                    color: "warning",
                    small: true,
                  },
                },
                {
                  text: "Confirmar",
                  callback: async () => {
                    this.$modals.setOptions({ loading: true });
                    await this.$services.alunosService.atualizarAluno({
                      ...aluno,
                      configuracao_id: this.user.configuracao_id,
                    });
                    this.$modals.close();
                    this.$toast.success("Aluno transferido com sucesso!");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    // text: true,
                    small: true,
                  },
                },
              ],
            });
          } else {
            this.$modals.info({
              title: "Este(a) aluno(a) já está cadastrado na sua escola!",
              message: "Verificamos que este(a) aluno(a) já está cadastrado na sua escola, ",
              buttons: [
                {
                  text: "Confirmar",
                  callback: () => {
                    this.$modals.close();
                    this.$toast.info("Este(a) aluno(a) já está cadastrado na sua escola");
                    this.$router.push({ name: "alunos" });
                  },
                  props: {
                    color: "success",
                    small: true,
                  },
                },
              ],
            });
          }
        }
      } catch (error) {
        this.$handleError(error);
      }
      this.pesquisandoINEP = false;
    },
    openTextFieldAutismo() {
      if (this.has_autismo) {
        this.$refs.myTextField.focus();
      }
      this.openDescriptionSelectionAutismo = this.has_autismo;
      this.textFieldValueAutismo = "";
    },
    openTextFieldDefMent() {
      this.openDescriptionSelectionDefMent = this.has_defMental;
      this.textFieldValueDefMent = "";
    },
    openTextFieldDefFis() {
      this.openDescriptionSelectionDefFis = this.has_defFisica;
      this.textFieldValueDefFis = "";
    },
    openTextFieldSaude() {
      if (this.selectedItemSaude) {
        this.textFieldOpen = this.selectedItemSaude === "Sim";
      } else {
        this.textFieldValueDetalhe = "";
      }
    },
    openTextFieldTipoAluno() {
      if (this.tipo_aluno) {
        this.textFieldOpenTipoAluno = this.tipo_aluno === "Atípico";
      } else {
        this.textFieldValueDetalheAtipico = "";
      }
    },
  },
};
</script>

<!-- SASS styling -->
<style lang="scss">
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}

.bottomButtons {
  overflow: auto;
}

.btn {
  float: right;
}

.options {
  margin-top: 20px;
  margin-left: 15px;
  font-size: large;
}

.texto {
  margin-left: 8px;
  margin-right: 10px;
  font-size: large;
  padding-bottom: 10px;
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: flex;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

.step.finish {
  background-color: #04aa6d;
}

.invalid {
  background-color: #ffdddd;
}

.tab {
  display: none;
}

.block {
  display: block;
}

.none {
  visibility: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.custom-padding {
  padding-top: 35px;
}
</style>
