<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'gestoesDeAulas' })"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Material da disciplina
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0">
        <UploadFiles
        :updatePage="updatePage"
        v-on:change="atualizarPagina"
        />
        <v-subheader>Lista de Materiais</v-subheader>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th width="5%" class="text-left">#</th>
                <th class="text-left">Material</th>
                <th class="text-left">Data de Envio</th>
                <th width="8%" class="text-left">Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(material, index) in materiais" :key="material.id" :class="index % 2 === 0 ? 'indigo lighten-4' : ''">
                <td>{{ material.id }}</td>
                <td>{{ material.material_aula }}</td>
                <td>{{ formatDate(material.data_envio_material, 'y-m-d') }}</td>
                <td>
                  <v-btn
                    color="red"
                    icon
                    @click="removerArquivo(material, index)"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn
                    color="blue"
                    icon
                    small
                    :href="material.arquivo"
                    target="_blank"
                    @click="downloadMaterial(material.id)"
                    >
                    <v-icon>mdi mdi-file-download</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">

      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import UploadFiles from "../../components/UploadFiles.vue";

export default {
  components: { UploadFiles },
  data() {
    return {
      materiais: [],
      updatePage: false,
    }
  },
  methods: {
    async fetchMateriais() {
      try {
        const gestaoDeAulasId = this.$route.params.gestaoDeAulasId;
        const response = await this.$services.gestoesDeAulasService.visualizarMaterialDeAula(gestaoDeAulasId);
        this.materiais = response;
        // console.log('Materiais recuperados:', this.materiais);
      } catch (error) {
        // console.error('Erro ao recuperar materiais:', error);
        this.$handleError(error);
      }
      this.updatePage = false;
    },
    async removerArquivo(material, index) {
      try {
        const gestaoDeAulasId = this.$route.params.gestaoDeAulasId;
        if (!gestaoDeAulasId) {
          // console.error('ID da gestão de aulas não encontrado');
          return;
        }
        const dataEnvio = material.data_envio_material;
        await this.$services.gestoesDeAulasService.removerMaterialDeAula(gestaoDeAulasId, dataEnvio, material);
        await this.fetchMateriais();
        // console.log('Arquivo removido com sucesso!');
      } catch (error) {
        // console.error('Erro ao remover o arquivo:', error);
        this.$handleError(error);
      }
    },
    async downloadMaterial(material) {
      const turmaId = this.$route.params.turmaId;
      const disciplinaId = this.$route.params.disciplinaId;
      // console.log(`material: ${material} Turma:${turmaId} Disciplina: ${disciplinaId}`);
      this.$loaderService.open("Baixando material");
      try {
        await this.$services.disciplinasService.downloadMaterialDisciplina(material, turmaId, disciplinaId);
      } catch (error) {
        // console.error('Erro ao baixar o material:', error);
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async atualizarPagina(val) {
      if(val == true){
        await this.fetchMateriais()
      }
      // console.log('valddqwdqw', val);
    },
    formatDate(dateStr, format) {
      switch (format) {
        case "y-m-d":
          return dateStr.split("-").reverse().join("/");
        case "d/m/y":
          return dateStr.split("/").reverse().join("-");
        default:
          return dateStr.split("-").reverse().join("/");
      }
    },
  },
  async mounted() {
    await this.fetchMateriais();

  },
};
</script>

<style>

</style>
