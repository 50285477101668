<template>
  <v-container>
    <v-form enctype="multipart/form-data" @submit.prevent="submitForm">
      <v-file-input
        name="arquivo[]"
        v-model="files"
        type="file"
        counter
        multiple
        show-size
        small-chips
        color="deep-purple accent-4"
        small-chipse-upload-files
        truncate-length="50"
        label="Selecione os arquivos"
        @change="handleFilesChange"
      ></v-file-input>
      <v-btn type="submit" color="success" :disabled="files.length === 0">Enviar Arquivos</v-btn>
    </v-form>
    <v-list class="my-6" v-if="files.length !== 0">
      <v-subheader class="my-1 mx-3" v-if="files.length !== 0">Arquivos selecionados para enviar:</v-subheader>
      <v-row justify="center">
        <v-chip
          v-for="(file, index) in files"
          :key="index"
          close
          @click:close="removeFile(index)"
          class="ma-1"
        >
          {{ file.name }}
        </v-chip>
      </v-row>
      <v-list-item v-if="files.length === 0">
        <v-list-item-content>
          <v-list-item-title>Nenhum arquivo adicionado.</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-container>
</template>

<script>
export default {
  props: {
    updatePage: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      files: [],
      dataEnvio: null,
      gestaoDeAulasId: null
    };
  },
  created() {
    // console.log('ID da gestão de aulas:', this.updatePage);
    this.gestaoDeAulasId = this.$route.params.gestaoDeAulasId;
    this.dataEnvio = new Date().toISOString().slice(0, 10);
  },
  methods: {
    handleFilesChange(selectedFiles) {
      this.files = selectedFiles;
    },
    removeFile(index) {
      this.files.splice(index, 1);
    },
    async submitForm() {
      // console.log('Arquivos selecionados:', this.files);
      try {
        const gestaoDeAulas = this.gestaoDeAulasId;
        const dataEnvio = this.dataEnvio;
        for (const file of this.files) {
          await this.$services.gestoesDeAulasService.uploadMaterialDeAula(gestaoDeAulas, dataEnvio, file);
        }
        // console.log('Arquivos enviados com sucesso!');
        this.files = [];
        // this.$router.go(0);
      } catch (error) {
        // console.error('Erro ao enviar arquivos:', error);
      }
      this.$emit('change', true)
    },
  },
};
</script>
