import Homepage from "@/Pages/AcessoResponsavel/HomePage.vue";
import MaterialDisciplinaPage from "@/Pages/AcessoResponsavel/MaterialDisciplinaPage.vue";

export default [
  {
    path: "/acesso-responsavel/home",
    name: "acessoResponsavel.home",
    component: Homepage,
  },
  // listar material disciplina
  {
    path: "/acesso-responsavel/materialDisciplina/:matriculaId/:turmaId/:disciplinaId",
    name: "acessoResponsavel.materialDisciplina",
    component: MaterialDisciplinaPage,
    props: true
  }
];
