import Axios from "@/plugins/Axios";

class ResponsavelAlunoService {
  async boletim(matricula_id) {
    const response = await Axios()
      .get(`relatorios/boletim/aluno/${matricula_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `boletim_aluno_${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async relatorioBncc(matricula_id) {
    const response = await Axios()
      .get(`relatorios/relatorioBncc/${matricula_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `relatorio_bncc${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async getParcelaPix(parcela_id) {
    // console.log("Parcel id:", parcela_id);
    const response = await Axios().get(`parcelas/parcelas/${parcela_id}/parcela-pix`);
    return response;
  }

  async estaPago(parcela_id) {
    const response = await Axios().get(`parcelas/parcelas/${parcela_id}/esta-pago`);
    // console.log(response);
    return response;
  }
  
}

export default new ResponsavelAlunoService();
